.projectsTitle {
  font-family: 'Rubik', sans-serif;
  font-size: 30px;
}

@media screen and (max-width: 767px) {
  .projectsTitle {
    font-size: 25px;
  }
}

.contactTitle {
  font-family: 'Rubik', sans-serif;
  font-size: 30px;
}

.contactFormStyle {
  border-radius: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: auto;
  margin-bottom: 50px;
}

.formFields {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.indivFields {
  margin-top: 10px;
  border-radius: 3px;
  border-color: rgb(232, 230, 230);
}

.expandable {
  color: red;
}

.contactForm:hover{
box-shadow: 10px 5px 5px rgb(197, 197, 197);;
}

.sendBtn {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  background-color: rgb(73, 97, 120) !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 12px !important;
  align-content: space-around;
  /* width: fit-content !important; */
  width: 200px !important;
  margin: auto;
  color: rgb(221, 209, 209);
}

.sendBtn:hover {
  background-color: #658ebd !important;
}

.cardFields {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}

.cardFields h5 {
  margin-right: 20px;
  color: rgb(73, 97, 120) !important;
}
@media screen and (max-width: 1000px) {
  .sendBtn {
    font-size: 14px;
    
  }
}

@media screen and (max-width: 767px) {
  .contactTitle {
    font-size: 25px;
  }
}

@media screen and (max-width: 400px) {
  .form-label {
    font-size: 14px !important;
  }
}

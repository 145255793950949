.titles {
  font-family: 'Dancing Script', cursive !important;
  font-size: 30px;
  margin: auto;
}

.experienceTitle {
  font-family: 'Rubik', sans-serif;
  font-size: 30px;
}

h2 {
  display: flex;
  justify-content: space-around;
}

.buttonText {
  font-size: 20px;
  text-decoration: none;
  color: rgb(221, 209, 209);
  font-weight: 500;
  font-family: 'Karla', sans-serif;
}

.resumeBtn {
  margin-bottom: 30px;
  background-color: rgb(73, 97, 120) !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 12px !important;
  align-content: space-around;
  /* width: fit-content !important; */
  width: 200px !important;
  margin: auto;
}

.resumeBtn:hover {
  background-color: #658ebd !important;
}

.languages {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.languages li {
  list-style-type: none;
  font-size: 20px;
  font-family: 'Rubik', sans-serif;
  display: flex;
  justify-content: space-around;
}

.educationTitle {
  font-family: 'Rubik', sans-serif;
  font-size: 25px !important;
  font-weight: bolder !important;
}

.educationTimeline {
  font-family: 'Karla', sans-serif;
  font-size: 20px !important;
}

.indivIcons {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .experienceTitle {
    font-size: 25px;
  }
  .titles {
    font-size: 25px !important;
    margin-top: 17px;
  }
  .languages li {
    font-size: 15px !important;
  }
  .educationSection {
    margin-top: -45px !important;
  }
  .educationTitle {
    font-size: 22px !important;
  }
  .educationTimeline {
    font-size: 20px !important;
  }
  .indivIcons {
    height: 60px;
    width: 50px;
    margin-right: 30px;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 414px) {
  .educationTitle {
    font-size: 18px !important;
  }
  .educationTimeline {
    font-size: 16px !important;
  }
}
.icons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

/* icons animation */

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.indivIcons {
  top: 50%;
  left: 50%;
  border-radius: 50%;
  line-height: 180px;
  text-align: center;
  text-transform: uppercase;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  color: white;
}
.indivIcons:hover {
  animation-name: bounce;
  -moz-animation-name: bounce;
}

.workExpTitle {
  font-family: 'Rubik', sans-serif;
  font-size: 30px;
}

.cardDendro:hover {
  box-shadow: 10px 5px 5px rgb(197, 197, 197);
}

.card-text {
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
}

@media screen and (max-width: 1000px) {
  .sendBtn {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .workExpTitle {
    font-size: 25px;
  }
}


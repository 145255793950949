@media screen and (max-width: 500px) {
  .indIcons {
    height: 25px !important;
    width: 25px !important;
  }
}

.ulFooter {
  position: relative;
  display: flex;
}
.ulFooter li {
  position: relative;
  list-style: none;
  margin: 0 20px;
  cursor: pointer;
}
/* .ulFooter li a {
  text-decoration: none;
} */
/* ul li a .indIcons {
  font-size: 6em;
  color: #222;
} */
/* ul li a::before {
  font-family: 'FontAwesome';
  position: absolute;
  top: 0;
  left: 0;
  font-size: 6em;
  height: 0;
  overflow: hidden;
  transition: 0.9s ease-in-out;
} */

/* email icon */
/* ul li:nth-child(1) a::before {
  content: '\f0e0';
  color: #28a8ea;
  border-bottom: 4px solid #28a8ea;
} */
.mailIcon:hover {
  color: #28a8ea;
}

/* github Icon */
/* ul li:nth-child(2) a::before {
  content: '\f092';
  color: black;
  border-bottom: 4px solid black;
} */
.githubIcon:hover {
  color: rgb(65, 63, 63);
}

/* linkedin icon */
/* ul li:nth-child(3) a::before {
  content: '\f08c';
  color: #0077b5;
  border-bottom: 4px solid #1da1f2;
} */
.linkedinIcon:hover {
  color: #0077b5;
}

/* instagram icon */
/* ul li:nth-child(4) a::before {
  content: '\e055';
  background-image: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 4px solid #dc2743;
  color: #dc2743;
} */
.instagramIcon:hover {
  color: #dc2743;
}

/* ul li:hover a::before {
  height: 100%;
} */

.indIcons {
  font-size: 2em;
  color: #202020; /* Default color */
  transition: color 0.2s ease-in-out;
}

.name {
  font-family: 'Dancing Script', cursive;
  font-size: 45px !important;
}

.navItems {
  font-family: 'Karla', sans-serif;
  font-size: 25px;
}

.navItems a {
  color: rgb(73, 97, 120) !important;
}

.navItems a:hover {
  color: rgb(149, 149, 208);
  font-weight: bolder;
}

.navItems a:active {
  color: rgb(149, 149, 208);
  font-weight: bolder;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px; /* height of sticky header */
}

/* responsiveness for navbar */
@media screen and (max-width: 991px) {
  html {
    scroll-padding-top: 270px;
  }
}

@media screen and (max-width: 415px) {
  .name {
    font-size: 35px !important;
  }
  html {
    scroll-padding-top: 240px !important;
  }
  .navItems {
    font-size: 20px !important;
  }
}

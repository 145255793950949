/* * {
  background-color: rgb(49, 58, 69);
  color: whitesmoke;
} */

.App {
  position: relative;
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  z-index: -1;
  background-position: center;
  opacity: 0.5;
}

@media screen and (max-width: 750px) {
  h1 {
    display: flex;
    justify-content: center;
    margin-left: 0px !important;
    /* margin: auto !important;
    margin-bottom: 30px !important;
    margin-top: 30px !important; */
  }
}
